import { Outlet, createRootRoute } from '@tanstack/react-router';
import { Toaster } from 'react-hot-toast';

import { Providers } from '@/components/providers/providers';
import { Flasher } from '@/utilities/flash';

import '@/styles/preflight.css';
import '@/styles/global.css';

import styles from './__root.module.css';

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  return (
    <Providers>
      <div className={styles.root}>
        <Outlet />
      </div>
      <Toaster />
      <Flasher />
    </Providers>
  );
}
