import toast from 'react-hot-toast';
import { useEffect } from 'react';

import * as storage from './storage';

type Type = 'info' | 'success' | 'error';

export function redirectWithFlash(route: string, message: string, type: Type = 'info') {
  storage.setFlash('toast', { message, type });
  window.location.assign(route);
}

export function Flasher() {
  useEffect(() => {
    const value = storage.getFlash<{ message: string; type: string }>('toast');

    if (!value) {
      return;
    }

    switch (value.type) {
      case 'info':
        toast.success(value.message);
        break;
      case 'success':
        toast.success(value.message);
        break;
      case 'error':
        toast.error(value.message);
        break;
    }
  }, []);

  return null;
}
