/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ProtectedImport } from './routes/_protected'
import { Route as AuthImport } from './routes/_auth'
import { Route as ProtectedIndexImport } from './routes/_protected/index'
import { Route as AuthRegisterImport } from './routes/_auth/register'
import { Route as AuthPasswordResetImport } from './routes/_auth/password-reset'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AuthForgotPasswordImport } from './routes/_auth/forgot-password'
import { Route as ProtectedReferralIndexImport } from './routes/_protected/referral/index'
import { Route as ProtectedHistoryIndexImport } from './routes/_protected/history/index'
import { Route as ProtectedAdminIndexImport } from './routes/_protected/admin/index'
import { Route as ProtectedRewardIdIndexImport } from './routes/_protected/reward/$id/index'
import { Route as ProtectedAdminEmailIndexImport } from './routes/_protected/admin/$email/index'

// Create/Update Routes

const ProtectedRoute = ProtectedImport.update({
  id: '/_protected',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const ProtectedIndexRoute = ProtectedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ProtectedRoute,
} as any)

const AuthRegisterRoute = AuthRegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPasswordResetRoute = AuthPasswordResetImport.update({
  id: '/password-reset',
  path: '/password-reset',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => AuthRoute,
} as any)

const ProtectedReferralIndexRoute = ProtectedReferralIndexImport.update({
  id: '/referral/',
  path: '/referral/',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedHistoryIndexRoute = ProtectedHistoryIndexImport.update({
  id: '/history/',
  path: '/history/',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedAdminIndexRoute = ProtectedAdminIndexImport.update({
  id: '/admin/',
  path: '/admin/',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedRewardIdIndexRoute = ProtectedRewardIdIndexImport.update({
  id: '/reward/$id/',
  path: '/reward/$id/',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedAdminEmailIndexRoute = ProtectedAdminEmailIndexImport.update({
  id: '/admin/$email/',
  path: '/admin/$email/',
  getParentRoute: () => ProtectedRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_protected': {
      id: '/_protected'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedImport
      parentRoute: typeof rootRoute
    }
    '/_auth/forgot-password': {
      id: '/_auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/password-reset': {
      id: '/_auth/password-reset'
      path: '/password-reset'
      fullPath: '/password-reset'
      preLoaderRoute: typeof AuthPasswordResetImport
      parentRoute: typeof AuthImport
    }
    '/_auth/register': {
      id: '/_auth/register'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof AuthRegisterImport
      parentRoute: typeof AuthImport
    }
    '/_protected/': {
      id: '/_protected/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof ProtectedIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/admin/': {
      id: '/_protected/admin/'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof ProtectedAdminIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/history/': {
      id: '/_protected/history/'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof ProtectedHistoryIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/referral/': {
      id: '/_protected/referral/'
      path: '/referral'
      fullPath: '/referral'
      preLoaderRoute: typeof ProtectedReferralIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/admin/$email/': {
      id: '/_protected/admin/$email/'
      path: '/admin/$email'
      fullPath: '/admin/$email'
      preLoaderRoute: typeof ProtectedAdminEmailIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/reward/$id/': {
      id: '/_protected/reward/$id/'
      path: '/reward/$id'
      fullPath: '/reward/$id'
      preLoaderRoute: typeof ProtectedRewardIdIndexImport
      parentRoute: typeof ProtectedImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthPasswordResetRoute: typeof AuthPasswordResetRoute
  AuthRegisterRoute: typeof AuthRegisterRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthPasswordResetRoute: AuthPasswordResetRoute,
  AuthRegisterRoute: AuthRegisterRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface ProtectedRouteChildren {
  ProtectedIndexRoute: typeof ProtectedIndexRoute
  ProtectedAdminIndexRoute: typeof ProtectedAdminIndexRoute
  ProtectedHistoryIndexRoute: typeof ProtectedHistoryIndexRoute
  ProtectedReferralIndexRoute: typeof ProtectedReferralIndexRoute
  ProtectedAdminEmailIndexRoute: typeof ProtectedAdminEmailIndexRoute
  ProtectedRewardIdIndexRoute: typeof ProtectedRewardIdIndexRoute
}

const ProtectedRouteChildren: ProtectedRouteChildren = {
  ProtectedIndexRoute: ProtectedIndexRoute,
  ProtectedAdminIndexRoute: ProtectedAdminIndexRoute,
  ProtectedHistoryIndexRoute: ProtectedHistoryIndexRoute,
  ProtectedReferralIndexRoute: ProtectedReferralIndexRoute,
  ProtectedAdminEmailIndexRoute: ProtectedAdminEmailIndexRoute,
  ProtectedRewardIdIndexRoute: ProtectedRewardIdIndexRoute,
}

const ProtectedRouteWithChildren = ProtectedRoute._addFileChildren(
  ProtectedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof ProtectedRouteWithChildren
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/password-reset': typeof AuthPasswordResetRoute
  '/register': typeof AuthRegisterRoute
  '/': typeof ProtectedIndexRoute
  '/admin': typeof ProtectedAdminIndexRoute
  '/history': typeof ProtectedHistoryIndexRoute
  '/referral': typeof ProtectedReferralIndexRoute
  '/admin/$email': typeof ProtectedAdminEmailIndexRoute
  '/reward/$id': typeof ProtectedRewardIdIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/password-reset': typeof AuthPasswordResetRoute
  '/register': typeof AuthRegisterRoute
  '/': typeof ProtectedIndexRoute
  '/admin': typeof ProtectedAdminIndexRoute
  '/history': typeof ProtectedHistoryIndexRoute
  '/referral': typeof ProtectedReferralIndexRoute
  '/admin/$email': typeof ProtectedAdminEmailIndexRoute
  '/reward/$id': typeof ProtectedRewardIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_protected': typeof ProtectedRouteWithChildren
  '/_auth/forgot-password': typeof AuthForgotPasswordRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/password-reset': typeof AuthPasswordResetRoute
  '/_auth/register': typeof AuthRegisterRoute
  '/_protected/': typeof ProtectedIndexRoute
  '/_protected/admin/': typeof ProtectedAdminIndexRoute
  '/_protected/history/': typeof ProtectedHistoryIndexRoute
  '/_protected/referral/': typeof ProtectedReferralIndexRoute
  '/_protected/admin/$email/': typeof ProtectedAdminEmailIndexRoute
  '/_protected/reward/$id/': typeof ProtectedRewardIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/forgot-password'
    | '/login'
    | '/password-reset'
    | '/register'
    | '/'
    | '/admin'
    | '/history'
    | '/referral'
    | '/admin/$email'
    | '/reward/$id'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/forgot-password'
    | '/login'
    | '/password-reset'
    | '/register'
    | '/'
    | '/admin'
    | '/history'
    | '/referral'
    | '/admin/$email'
    | '/reward/$id'
  id:
    | '__root__'
    | '/_auth'
    | '/_protected'
    | '/_auth/forgot-password'
    | '/_auth/login'
    | '/_auth/password-reset'
    | '/_auth/register'
    | '/_protected/'
    | '/_protected/admin/'
    | '/_protected/history/'
    | '/_protected/referral/'
    | '/_protected/admin/$email/'
    | '/_protected/reward/$id/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  ProtectedRoute: typeof ProtectedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  ProtectedRoute: ProtectedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_protected"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/forgot-password",
        "/_auth/login",
        "/_auth/password-reset",
        "/_auth/register"
      ]
    },
    "/_protected": {
      "filePath": "_protected.tsx",
      "children": [
        "/_protected/",
        "/_protected/admin/",
        "/_protected/history/",
        "/_protected/referral/",
        "/_protected/admin/$email/",
        "/_protected/reward/$id/"
      ]
    },
    "/_auth/forgot-password": {
      "filePath": "_auth/forgot-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/password-reset": {
      "filePath": "_auth/password-reset.tsx",
      "parent": "/_auth"
    },
    "/_auth/register": {
      "filePath": "_auth/register.tsx",
      "parent": "/_auth"
    },
    "/_protected/": {
      "filePath": "_protected/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/admin/": {
      "filePath": "_protected/admin/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/history/": {
      "filePath": "_protected/history/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/referral/": {
      "filePath": "_protected/referral/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/admin/$email/": {
      "filePath": "_protected/admin/$email/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/reward/$id/": {
      "filePath": "_protected/reward/$id/index.tsx",
      "parent": "/_protected"
    }
  }
}
ROUTE_MANIFEST_END */
