import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

const queryClient = new QueryClient();

type ProvidersProps = {
  children: ReactNode;
};

export function Providers(props: ProvidersProps) {
  const { children } = props;

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} />
      <TanStackRouterDevtools position="bottom-right" /> */}
    </QueryClientProvider>
  );
}
