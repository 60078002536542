export function setItem(key: string, payload: object, expiryInHours: number): void {
  const now = new Date();
  const item = {
    value: payload,
    expiry: now.getTime() + expiryInHours * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItem<T>(key: string, defaultValue: T): T {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return defaultValue;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return defaultValue;
  }

  return item.value;
}

export function setFlash<T>(key: string, value: T): void {
  const flashValue = JSON.stringify({ value });
  localStorage.setItem(key, flashValue);
}

export function getFlash<T>(key: string): T | null {
  const flashValue = localStorage.getItem(key);

  // Clear the value immediately after reading
  localStorage.removeItem(key);

  if (flashValue) {
    return JSON.parse(flashValue).value;
  }

  return null;
}
